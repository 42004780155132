export const qArticleValidator = async (request, addNewCategorie, categorieNewValue ) => {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const digit = /^\d+$/;
    let errorInput = {
        error: false,
        path : '',
        message : ''
    }

    let isValid = true
    if (request.libelle === "") {
        errorInput.path = "libelle";
        errorInput.message = "Veillez entrer un libellé";
        isValid = false;
      } else if (request.categorie === "" && addNewCategorie === false) {
        errorInput.path = "categories";
        errorInput.message = "Veillez entrer une categorie";
        isValid = false;
      } else if (categorieNewValue.value === null || addNewCategorie === true) {
        errorInput.path = "categories";
        errorInput.message = "Veillez entrer une categorie";
        isValid = false;
      } else if (request.type === "") {
        errorInput.path = "type";
        errorInput.message = "Séléction le type de produit";
        isValid = false;
      } else if (request.prix_vente === "") {
        errorInput.path = "prix_vente";
        errorInput.message = "Veillez entrer un prix de vente valide";
        isValid = false;
      } else if (request.seuil === "" && request.type === "produit") {
        errorInput.path = "seuil";
        errorInput.message = "Veillez entrer un seuil";
        isValid = false;
      } else if (request.qte === "" && request.type === "produit") {
        errorInput.path = "qte";
        errorInput.message = "Veillez entrer une quantité raisonnable";
        isValid = false;
      } 

      return {isValid : isValid, EI: errorInput}
}

